// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".drag-and-snap-manager__draggableArea___ifUCa{position:absolute;left:0;top:0;width:100%;height:100%;pointer-events:none}.drag-and-snap-manager__draggableItem___7xiLg{position:absolute;pointer-events:auto}.drag-and-snap-manager__draggableItem___7xiLg.drag-and-snap-manager__dragging___OTNT0{cursor:move}.drag-and-snap-manager__draggableItem___7xiLg:not(.drag-and-snap-manager__dragging___OTNT0){transition:top .3s ease-in,right .3s ease-in,bottom .3s ease-in}.drag-and-snap-manager__draggableItem___7xiLg.drag-and-snap-manager__prePlayback___fZHf3{bottom:-50px}\n", "",{"version":3,"sources":["webpack://components/drag-and-snap-manager/drag-and-snap-manager.scss"],"names":[],"mappings":"AAEA,8CACE,iBAAkB,CAClB,MAAO,CACP,KAAM,CACN,UAAW,CACX,WAAY,CACZ,mBAAoB,CACrB,8CAEC,iBAAkB,CAClB,mBAAoB,CAFtB,sFAII,WAAY,CAJhB,4FAOI,+DAAkH,CAPtH,yFAUI,YAAa","sourcesContent":["@import '~styles/variables';\n\n.draggableArea {\n  position: absolute;\n  left: 0;\n  top: 0;\n  width: 100%;\n  height: 100%;\n  pointer-events: none;\n}\n.draggableItem {\n  position: absolute;\n  pointer-events: auto;\n  &.dragging {\n    cursor: move;\n  }\n  &:not(.dragging) {\n    transition: top $animation-duration ease-in, right $animation-duration ease-in, bottom $animation-duration ease-in;\n  }\n  &.prePlayback {\n    bottom: -50px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"draggableArea": "drag-and-snap-manager__draggableArea___ifUCa",
	"draggableItem": "drag-and-snap-manager__draggableItem___7xiLg",
	"dragging": "drag-and-snap-manager__dragging___OTNT0",
	"prePlayback": "drag-and-snap-manager__prePlayback___fZHf3"
};
export default ___CSS_LOADER_EXPORT___;
